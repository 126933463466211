@import url(https://fonts.googleapis.com/css2?family=Baloo+Tamma+2&display=swap);
:root {
  --background: #000814;
  --background-alternate: #0b1625;
  --background-dark: #09111d;
  --foreground: #f8f8f2;
  --accent: #75715e;
  --red: #ff0040;
  --orange: #ff5400;
  --yellow: #ffbe00;
  --green: #00ff1b;
  --blue: #0086f9;
  --purple: #7c00ff;
}

html,
body {
  margin: 0;
  background-color: #000814;
  background-color: var(--background);
  font-family: "Baloo Tamma 2", cursive;
  color: white;
  scroll-behavior: smooth;
}

footer {
  text-align: center;
  margin: 1rem auto;
}

footer a {
  background: linear-gradient(
    90deg,
    #ff0040,
    #ff5400,
    #ffbe00,
    #00ff1b,
    #0086f9,
    #7c00ff);
  background: linear-gradient(
    90deg,
    var(--red),
    var(--orange),
    var(--yellow),
    var(--green),
    var(--blue),
    var(--purple)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1200% 1200%;
  animation: gradientAnimation 5s ease infinite;
}

.container {
  max-width: 950px;
  margin: 0 auto;
  padding: 0 20px;
}

.top {
  width: 100%;
  text-align: center;
}

.top h1 {
  font-size: 3rem;
  margin-bottom: 0;
  background: linear-gradient(
    90deg,
    #ff0040,
    #ff5400,
    #ffbe00,
    #00ff1b,
    #0086f9,
    #7c00ff);
  background: linear-gradient(
    90deg,
    var(--red),
    var(--orange),
    var(--yellow),
    var(--green),
    var(--blue),
    var(--purple)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1200% 1200%;
  animation: gradientAnimation 5s ease infinite;
}

.top textarea {
  width: 600px;
  height: 300px;
  resize: none;
  outline: none;
  border-radius: 20px;
  padding: 1.5rem;
}

.top p {
  font-size: 2rem;
  margin: 0 0 1rem 0;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 700px) {
  .top p {
    font-size: 1.5rem;
  }

  .top textarea {
    width: auto;
    height: 200px;
  }
}

::selection {
  background-color: #7c00ff;
  background-color: var(--purple);
  color: white;
}

::-webkit-scrollbar {
  display: none;
}

